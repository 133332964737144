import { useState, useEffect } from 'react'

import { retrievePayOffEligibility, getLoan } from '../services/api'

import { extractLoan } from '../Utils/apiExtractors'
import { Cache } from 'aws-amplify'
import { getCurrentUserAttribute } from '../services/auth'

export const useRetrievePayOffEligibility = () => {
  const [payOffEligibility, setPayOffEligibility] = useState({
    loading: true,
  })

  useEffect(()=>{
    const intervalId = setInterval(async ()=>{
      const user = localStorage.getItem('ajs_user_traits');
      if(!user){
        clearInterval(intervalId);
        return
      };

      const personId = await getCurrentUserAttribute('custom:person_id')
      const cachedLoan = Cache.getItem('GET_LOAN_KEY'+personId)
      if (cachedLoan){
        clearInterval(intervalId);
        const loan = cachedLoan.data.loan.loanData
        let payoffData = null
        let error = null
        try {
          const loanNumber = loan.loanId
          const state = loan.property.state

          payoffData = await retrievePayOffEligibility(loanNumber)

          if (state === 'OR' || state === 'NV') {
            payoffData.data.getPayoffEligibility.info.eligible = false
          }
        } catch (err) {
          console.log('err', err)
          error = err
        } finally {
          setPayOffEligibility({
            data: payoffData && payoffData.data.getPayoffEligibility.info || null,
            error,
            loading: false,
          })
        }
      }

    },500)

    return ()=>{
      clearInterval(intervalId);
    }
  },[])

  return payOffEligibility
}