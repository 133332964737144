import React, { useState } from "react";
import styled from "styled-components";
import EyeIcon from "../assets/eye-icon";
import NoEyeIcon from "../assets/no-eye-icon";
import { FORGOT_PASSWORD } from "./AuthLayout";
import { Auth } from "aws-amplify";
import { getRandomLetters } from "../services/auth";
import { buildTestID } from "../Utils/utils";
import { Spinner } from "react-bootstrap";

const StyledLoginForm = styled.div`
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 20px;
  margin-left: 15px;
  margin-bottom: 40px;

  .label {
    color: var(--Black, #000);
    font-family: Lato, sans-serif;
    font-weight: 500;
    line-height: 137.5%;
    // margin-top: 82px;
  
    @media (max-width: 991px) {
      max-width: 100%;
      margin-top: 0;
    }
  }

  .input {
    font-family: Lato, sans-serif;
    border-radius: 10px;
    border: 1px solid #a9a9a9;
    background-color: var(--White, #fff);
    // margin-top: 6px;
    justify-content: center;
    color: var(--Grey-2, #777);
    font-weight: 400;
    line-height: 137.5%;
    width: 100%;
    
    display: flex;
    padding: 10px 8px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 20px;
  
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }

  .disabled {
    border: none;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.12);
    cursor: default;
  }

  .spinner-icon {
    margin-left: 10px;
  }

  .password-wrapper {
    position: relative;
    
    svg {
      position: absolute;
      top: 14px;
      right: 14px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
  }

  @media (min-width: 1281px) {
    width: 610px;
    padding: 80px 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakPoints.xlarge}) {

  }
`;

const BottomSection = styled.div`
  justify-content: space-between;
  display: flex;
  margin-top: 30px;
  gap: 10px;
  font-size: 18px;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const ForgotPasswordLink = styled.div`
  color: var(--Royal-Blue, #074ee8);
  font-family: Lato, sans-serif;
  font-weight: 400;
  line-height: 122%;
  text-decoration-line: underline;
  flex-grow: 1;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (min-width: 1300px) {
    cursor:pointer;
    max-width:35%;
  }
`;

const SignInButton = styled.button`
  font-family: Lato, sans-serif;
  justify-content: center;
  border-radius: 10px;
  background-color: var(--Dark-Grey, #475468);
  color: var(--Surface, #fff);
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  padding: 10px 20px;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const LoginForm = ({ setActiveFormTab, setAuthState, setAuthData, setUserInfo, setIsAuthenticated }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState('')
  const [randomLetters, setRandomLetters] = useState('')
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [loading, setLoading] = useState(false)

  const setErrorForAda = (error) => {
    setRandomLetters(getRandomLetters(2))
    setError(error)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setDisableSubmit(true)
    setLoading(true)
    try {
      if (!username || !password)
        throw new Error(
          'Please make sure to enter both an email and password.',
        )

      const user = await Auth.signIn(
        username.toLowerCase().trim(),
        password,
      )

      const { challengeName } = user

      if (!challengeName) {
        setAuthState('signedIn')
        setAuthData({ user, redirect: true })
        setUserInfo(user)
        setIsAuthenticated(!!user)
      }

      switch (challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
          setAuthState('requireNewPassword', user)
          break

        case 'SMS_MFA':
          setAuthState('confirmSignIn')
          setAuthData(user)
          break

        default:
      }
    } catch (err) {
      setLoading(false)
      setDisableSubmit(false)
      if (err.code === 'UserNotConfirmedException') {
        setAuthState('confirmSignUp', { username })
        setAuthData({ username })
      }
      else setErrorForAda('Incorrect username or password')
      return
    }
  }

  return (
    <StyledLoginForm>
      <form onSubmit={handleSubmit}>
        <label className="label">Email</label>
        <input
          type="email"
          className="input"
          placeholder="Type your email"
          name="email"
          onChange={(e) => setUsername(e.target.value)}
          id="email"
          aria-required="true"
          data-testid={buildTestID(
            'Enter your email',
            '/home',
            'sign_in',
          )} />
        <label className="label">Password</label>
        <div className="password-wrapper" >
          <input
            type={`${showPassword ? 'text' : 'password'}`}
            className="input"
            placeholder="Type your password" name="password"
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            aria-required="true"
            autoComplete="off"
            datatestid="password_signin"

          />
          <span onClick={() => setShowPassword(showPassword => !showPassword)}  >
            {!showPassword ? (<EyeIcon color="black" />) : (<NoEyeIcon color="black" />)}
          </span>
        </div>
        <p role="alert">
          <span
            data-testid="error-message"
            className="error-message"
          >
            {error}
            <span
              style={{ visibility: 'hidden' }}
              dangerouslySetInnerHTML={{
                __html: randomLetters,
              }}
            ></span>
          </span>
        </p>
        <BottomSection>
          <ForgotPasswordLink onClick={() => setActiveFormTab(FORGOT_PASSWORD)}>Forgot your password?</ForgotPasswordLink>
          <SignInButton type="submit" disabled={disableSubmit || (!username.length || !password.length)} className={`${disableSubmit || (!username.length || !password.length) ? 'disabled' : ''}`}>Sign In{loading && (
            <Spinner
              data-testid="spinner"
              animation="border"
              role="status"
              size="sm"
              className="spinner-icon"
            />
          )}</SignInButton>
        </BottomSection>
      </form>
    </StyledLoginForm >
  );
}

export default LoginForm;