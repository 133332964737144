import { useState, useEffect } from 'react'

import { retrieveLocEligibility, getLoan } from '../services/api'

export const useRetrieveLocEligibility = () => {
  const [locStatus, setLocStatus] = useState({ loading: true })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const cachedLoanId = sessionStorage.getItem('loanId')

      if (cachedLoanId) {
        clearInterval(intervalId); // Stop checking
        let locStatus = null
        let error = null
        try {
          if (cachedLoanId) {
            const loanNumber = JSON.parse(cachedLoanId)
            locStatus = await retrieveLocEligibility(loanNumber)
          }
        } catch (err) {
          error = err
        } finally {
          if (error)
            setLocStatus({ data: { data: { getLOCEligibility: { info: null } } }, error, loading: false })
          else
            setLocStatus({ data: locStatus, error, loading: false })
        }
      }
    }, 500); // Check every second (500 milliseconds)

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [])

  return locStatus
}
